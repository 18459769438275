.account-dropdown-toggle {
  border-color: $secondary-darker !important;
}

.account-toggle-item {
  border-color: $secondary-darker !important;

  svg {
    color: $secondary-darker !important;
  }

  &:hover {
    cursor: pointer;
    border-color: $secondary !important;
  }
}

.dropdown-menu {
  background-color: #000;
  color: $secondary-darker;
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid $gray-900;

  &::-webkit-scrollbar {
    background-color: #000;
    width: 12px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
    border-radius: 6px;
    border: 2px solid #000;
  }

  .border-top {
    border-top-color: $gray-900 !important;
  }

  .account-item:hover {
    cursor: pointer;
    background-color: $gray-900;
  }
}

.balance-text {
  font-size: 0.85rem !important;
  color: $secondary;

  &--available {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    pointer-events: none;
  }
}
